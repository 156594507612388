"use strict";
var mailgoVersion = "0.8.2";
! function() {
  function t() {
    var e = de("div");
    e.style.display = "none", e.id = "mailgo", e.classList.add("m-modal");
    var a = de("div");
    a.className = "m-modal-back", e.appendChild(a);
    var t = de("div");
    t.className = "m-modal-content", e.appendChild(t), (_ = de("strong")).id = "m-title", _.className = "m-title", t.appendChild(_);
    var o = de("div");
    o.id = "m-details", o.className = "m-details", (P = de("p")).id = "m-cc";
    var n = de("span");
    n.className = "w-500";
    var i = le("cc ");
    n.appendChild(i), (F = de("span")).id = "m-cc-value", P.appendChild(n), P.appendChild(F), o.appendChild(P), (B = de("p")).id = "m-bcc";
    var d = de("span");
    d.className = "w-500";
    var l = le("bcc ");
    d.appendChild(l), (G = de("span")).id = "m-bcc-value", B.appendChild(d), B.appendChild(G), o.appendChild(B), (O = de("p")).id = "m-subject";
    var s = de("span");
    s.className = "w-500";
    var r = le("subject ");
    s.appendChild(r), (V = de("span")).id = "m-subject-value", O.appendChild(s), O.appendChild(V), o.appendChild(O), (Z = de("p")).id = "m-body";
    var c = de("span");
    c.className = "w-500";
    var m = le("body ");
    c.appendChild(m), ($ = de("span")).id = "m-body-value", Z.appendChild(c), Z.appendChild($), o.appendChild(Z), t.appendChild(o), (q = de("a")).id = "m-gmail", q.href = oe, q.classList.add("m-open"), q.classList.add("m-gmail");
    var p = le("open in ");
    q.appendChild(p);
    var b = de("span");
    b.className = "w-500";
    var h = le("Gmail");
    b.appendChild(h), q.appendChild(b), t.appendChild(q), (J = de("a")).id = "m-outlook", J.href = oe, J.classList.add("m-open"), J.classList.add("m-outlook");
    var u = le("open in ");
    J.appendChild(u);
    var f = de("span");
    f.className = "w-500";
    var g = le("Outlook");
    f.appendChild(g), J.appendChild(f), t.appendChild(J), (K = de("a")).id = "m-open", K.href = oe, K.classList.add("m-open"), K.classList.add("m-default");
    var v = de("span");
    v.className = "w-500";
    var w = le("open");
    v.appendChild(w);
    var x = le(" default");
    K.appendChild(v), K.appendChild(x), t.appendChild(K), (ae = de("a")).id = "m-copy", ae.href = oe, ae.classList.add("m-copy"), ae.classList.add("w-500");
    var C = le("copy");
    ae.appendChild(C), t.appendChild(ae), t.appendChild(ie()), document.body.appendChild(e), a.addEventListener("click", ne);
    var y = de("div");
    y.style.display = "none", y.id = "mailgo-tel", y.classList.add("m-modal");
    var k = de("div");
    k.className = "m-modal-back", y.appendChild(k);
    var L = de("div");
    L.className = "m-modal-content", y.appendChild(L), (D = de("strong")).id = "m-tel-title", D.className = "m-title", L.appendChild(D), (Q = de("a")).id = "m-tg", Q.href = oe, Q.classList.add("m-open"), Q.classList.add("m-tg"), Q.style.display = "none";
    var z = le("open in ");
    Q.appendChild(z);
    var A = de("span");
    A.className = "w-500";
    var E = le("Telegram");
    A.appendChild(E), Q.appendChild(A), L.appendChild(Q), (X = de("a")).id = "m-wa", X.href = oe, X.classList.add("m-open"), X.classList.add("m-wa");
    var N = le("open in ");
    X.appendChild(N);
    var j = de("span");
    j.className = "w-500";
    var R = le("WhatsApp");
    j.appendChild(R), X.appendChild(j), L.appendChild(X), (Y = de("a")).id = "m-skype", Y.href = oe, Y.classList.add("m-open"), Y.classList.add("m-skype");
    var U = le("open in ");
    Y.appendChild(U);
    var I = de("span");
    I.className = "w-500";
    var S = le("Skype");
    I.appendChild(S), Y.appendChild(I), L.appendChild(Y), (ee = de("a")).id = "m-call", ee.href = oe, ee.classList.add("m-open"), ee.classList.add("m-default");
    var T = de("span");
    T.className = "w-500";
    var M = le("call");
    T.appendChild(M);
    var W = le(" as default");
    ee.appendChild(T), ee.appendChild(W), L.appendChild(ee), (te = de("a")).id = "m-tel-copy", te.href = oe, te.classList.add("m-copy"), te.classList.add("w-500");
    var H = le("copy");
    te.appendChild(H), L.appendChild(te), L.appendChild(ie()), document.body.appendChild(y), k.addEventListener("click", ne)
  }

  function o(e, a) {
    var t = 0 < arguments.length && void 0 !== e ? e : r,
      o = 1 < arguments.length ? a : void 0;
    if (t === r) {
      if (o.href && o.href.toLowerCase().startsWith(d)) {
        p = decodeURIComponent(o.href.split("?")[0].split(d)[1].trim()), m = new URL(o.href);
        var n = new URLSearchParams(m.search);
        h = n.get("cc"), u = n.get("bcc"), f = n.get("subject"), g = n.get("body")
      } else p = o.getAttribute("data-address") + "@" + o.getAttribute("data-domain"), m = new URL(d + encodeURIComponent(p)), h = o.getAttribute("data-cc-address") + "@" + o.getAttribute("data-cc-domain"), u = o.getAttribute("data-bcc-address") + "@" + o.getAttribute("data-bcc-domain"), f = o.getAttribute("data-subject"), g = o.getAttribute("data-body");
      if (!H(p.split(","))) return;
      h && !H(h.split(",")) && (h = ""), u && !H(u.split(",")) && (u = ""), _.innerHTML = p.split(",").join("<br/>"), h ? (P.style.display = "block", F.innerHTML = h.split(",").join("<br/>")) : P.style.display = "none", u ? (B.style.display = "block", G.innerHTML = u.split(",").join("<br/>")) : B.style.display = "none", f ? (O.style.display = "block", V.textContent = f) : O.style.display = "none", g ? (Z.style.display = "block", $.textContent = g) : Z.style.display = "none", q.addEventListener("click", C), J.addEventListener("click", y), b = I(p), K.addEventListener("click", k), ae.addEventListener("click", function() {
        return N(p)
      })
    }
    t === c && (o.href && o.href.toLowerCase().startsWith(l) ? v = decodeURIComponent(o.href.split("?")[0].split(l)[1].trim()) : o.href && o.href.toLowerCase().startsWith(s) ? v = decodeURIComponent(o.href.split("?")[0].split(s)[1].trim()) : o.hasAttribute("data-tel") && (v = o.getAttribute("data-tel"), o.getAttribute("data-msg")), o.hasAttribute("data-telegram") && (w = o.getAttribute("data-telegram")), o.hasAttribute("data-skype") && (x = o.getAttribute("data-skype")), D.innerHTML = v, X.addEventListener("click", A), w && (M("m-tg", "block"), Q.addEventListener("click", L)), Y.addEventListener("click", z), ee.addEventListener("click", E), te.addEventListener("click", function() {
      return N(v)
    })), R(t), document.addEventListener("keydown", j)
  }

  function n(e, a) {
    var t = 1 < arguments.length && void 0 !== a ? a : r;
    return t === r ? e.href && e.href.toLowerCase().startsWith(d) && !e.classList.contains("no-mailgo") || e.hasAttribute("data-address") && (e.href && "#mailgo" === e.getAttribute("href").toLowerCase() || e.classList && e.classList.contains("mailgo")) : t === c && (e.href && (e.href.toLowerCase().startsWith(l) || e.href.toLowerCase().startsWith(s)) && !e.classList.contains("no-mailgo") || e.hasAttribute("data-tel") && e.href && "#mailgo" === e.getAttribute("href").toLowerCase() || e.classList && e.classList.contains("mailgo"))
  }

  function i(a) {
    if (document.contains(S("mailgo")) && document.contains(S("mailgo-tel")) && !U(r) && !U(c)) {
      var e = a.path || a.composedPath && a.composedPath() || W(a.target);
      e && e.forEach(function(e) {
        if (!(e instanceof HTMLDocument || e instanceof Window)) return n(e, r) ? (a.preventDefault(), void o(r, e)) : n(e, c) ? (a.preventDefault(), void o(c, e)) : void 0
      })
    }
  }

  function a(e) {
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e)
  }
  var _, D, P, B, O, Z, F, G, V, $, q, J, K, Q, X, Y, ee, ae, te, d = "mailto:",
    l = "tel:",
    s = "callto:",
    r = "mail",
    c = "tel",
    oe = "javascript:void(0);",
    m = "",
    p = "",
    b = "",
    h = "",
    u = "",
    f = "",
    g = "",
    v = "",
    w = "",
    x = "",
    C = function() {
      var e = "https://mail.google.com/mail/u/0/?view=cm&source=mailto&to=" + encodeURIComponent(p);
      h && (e = e.concat("&cc=" + encodeURIComponent(h))), u && (e = e.concat("&bcc=" + encodeURIComponent(u))), f && (e = e.concat("&subject=" + f)), g && (e = e.concat("&body=" + g)), window.open(e, "_blank"), ne()
    },
    y = function() {
      var e = "https://outlook.live.com/owa/?path=/mail/action/compose&to=" + encodeURIComponent(p);
      f && (e = e.concat("&subject=" + f)), g && (e = e.concat("&body=" + g)), window.open(e, "_blank"), ne()
    },
    k = function() {
      e(b), ne()
    },
    L = function() {
      var e = "https://t.me/" + w;
      window.open(e, "_blank"), ne()
    },
    z = function() {
      var e = "skype:" + ("" !== x ? x : v);
      window.open(e, "_blank"), ne()
    },
    A = function() {
      var e = "https://wa.me/" + v;
      window.open(e, "_blank"), ne()
    },
    E = function() {
      var e = "tel:" + v;
      window.open(e), ne()
    },
    N = function(e) {
      var a;
      se(e), (a = U(r) ? ae : te).textContent = "copied", setTimeout(function() {
        a.textContent = "copy", ne()
      }, 999)
    },
    j = function(e) {
      if (U(r)) switch (e.keyCode) {
        case 27:
          ne();
          break;
        case 71:
          C();
          break;
        case 79:
          y();
          break;
        case 32:
        case 13:
          k();
          break;
        case 67:
          N(p);
          break;
        default:
          return
      } else if (U(c)) switch (e.keyCode) {
        case 27:
          ne();
          break;
        case 84:
          L();
          break;
        case 87:
          A();
          break;
        case 32:
        case 13:
          E();
          break;
        case 67:
          N(v);
          break;
        default:
          return
      }
    },
    R = function(e) {
      var a = 0 < arguments.length && void 0 !== e ? e : r;
      a !== r ? a !== c || M("mailgo-tel", "flex") : M("mailgo", "flex")
    },
    ne = function() {
      M("mailgo", "none"), M("mailgo-tel", "none"), document.removeEventListener("keydown", j)
    },
    U = function(e) {
      var a = 0 < arguments.length && void 0 !== e ? e : r;
      return a === r ? "flex" === T("mailgo") : a === c && "flex" === T("mailgo-tel")
    },
    ie = function() {
      var e = de("a");
      e.href = "https://mailgo.js.org?ref=mailgo-modal", e.className = "m-by", e.target = "_blank", e.rel = "noopener noreferrer";
      var a = le("mailgo.js.org");
      return e.appendChild(a), e
    },
    de = function(e) {
      return document.createElement(e)
    },
    le = function(e) {
      return document.createTextNode(e)
    },
    e = function(e) {
      return window.location.href = d + atob(e)
    },
    I = function(e) {
      return btoa(e)
    },
    S = function(e) {
      return document.getElementById(e)
    },
    T = function(e) {
      return S(e).style.display
    },
    M = function(e, a) {
      return S(e).style.display = a
    },
    W = function(e) {
      for (var a = []; e;) {
        if (a.push(e), "HTML" === e.tagName) return a.push(document), a.push(window), a;
        e = e.parentElement
      }
    },
    H = function(e) {
      return e.every(a)
    },
    se = function(e) {
      var a = de("textarea");
      a.value = e, a.setAttribute("readonly", ""), a.style.position = "absolute", a.style.left = "-9999px", document.body.appendChild(a);
      var t = 0 < document.getSelection().rangeCount && document.getSelection().getRangeAt(0);
      a.select(), document.execCommand("copy"), document.body.removeChild(a), t && (document.getSelection().removeAllRanges(), document.getSelection().addRange(t))
    };
  ! function() {
    if (window && "undefined" != typeof window) {
      var e = de("style");
      e.id = "mailgo-style", e.type = "text/css";
      var a = le('');
      e.appendChild(a), document.head.appendChild(e), document.addEventListener("DOMContentLoaded", t), document.addEventListener("click", i)
    }
  }()
}();